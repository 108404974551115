import React from "react"
import Layout from "../layouts/index"
import Img from "gatsby-image"
import DetailHeader from '../components/detailHeader/detailHeader'
import Seo from "../components/seo/seo"
import ReactHtmlParser from 'react-html-parser';

import '../components/basicPage/basic-page.scss'

export default class BlogList extends React.Component {
  render() {

    const url = this.props.location.href;
    const title = this.props.pageContext.node.path.alias.slice(1);
    const allFiles = this.props.pageContext.allFiles;
    const node = this.props.pageContext.node;
    
    let article_body_elements = new ReactHtmlParser(node.body.processed, {
      transform: function transform(imgNode) {
        if (imgNode.type === 'tag' && imgNode.name === 'img') {
          let uuid = imgNode.attribs["data-entity-uuid"];
          let i = 0;
          for (i = 0; i < allFiles.length; i++) {
            if (allFiles[i].node.uuid === uuid &&
              allFiles[i].node.localFile) {
              return <Img fluid={allFiles[i].node.localFile.childImageSharp.fluid}/>
            }
          }
        }
  
        return undefined;
      }
    });

    return (
      <Layout>
        <Seo title={title} url={url}/>
        <DetailHeader title={this.props.pageContext.node.title}/>
        <div className="wd-basicpage">
            <div className="wd-basicpage__container">
                <div className="wd-basicpage__content">
                {/* {this.props.pageContext.node.relationships.field_media_img.relationships.field_media_image != null &&
                  <Img className="wd-basicpage__img" fixed={this.props.pageContext.node.relationships.field_media_img.relationships.field_media_image.localFile.childImageSharp.fixed}/>
                } */}
                  <div className="wd-basicpage__description">
                    { article_body_elements }
                  </div>
                </div>
            </div>
        </div>
      </Layout>
    )
  }
}

